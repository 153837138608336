import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import Scene from 'types/scene/scene.type'
import axios from 'axios'
import toast from 'react-hot-toast'
import SceneFrame from 'types/scene/scene-frame.type'
import { RoutePaths } from 'routes/routes'

interface SceneState {
  loading: boolean
  error?: string | null
  scenes: Scene[]
}

const initialState: SceneState = {
  loading: false,
  error: null,
  scenes: [],
}

export const addSceneWithDefaults = createAsyncThunk('scenes/add', async (id: number) => {
  const response = await fetch(RoutePaths.api.timeline.get + id)
  return await response.json()
})

// Not currently in use, may be removed
// export const refreshScenes = createAsyncThunk('scenes/refresh', async () => {
//   const response = await fetch('https://tv-platform-2022.herokuapp.com/getJsonForTimelineWithId/1')
//   return await response.json()
// })

export const sceneSlice = createSlice({
  name: 'scenes',
  initialState,
  reducers: {
    updateScenes: (state: any, action) => {
      let frameIndex: any = null

      state.scenes.map((scene: Scene) => {
        scene.fr.map((frame: SceneFrame, index: number) => {
          if (frame.id === action.payload.scene.id) {
            frameIndex = index
          }
          return frameIndex
        })
      })

      const scenes = [...state.scenes]
      void { ...state.scenes, ...(scenes[action.payload.mainIndex].fr[frameIndex] = action.payload.scene) }
      //       let frameIndex: any = null
      //
      // console.log(action.payload)
      //       const sceneIndex = state.scenes.findIndex((scene: Scene) =>
      //         scene.fr.some((frame: SceneFrame) => frame.id === action.payload.id)
      //       )
      //
      //
      //       state.scenes.map((scene: Scene) => {
      //         scene.fr.map((frame: SceneFrame, index: number) => {
      //           if (frame.id === action.payload.id) {
      //             frameIndex = index
      //           }
      //           return frameIndex
      //         })
      //       })
      //
      //       const scenes = [...state.scenes]
      //       void { ...state.scenes, ...(scenes[sceneIndex].fr[frameIndex] = action.payload) }
    },

    saveScenes: (state, { payload }) => {
      const { scenes } = state

      const promise = axios
        .post(RoutePaths.api.timeline.update, {
          id: payload.id,
          scenes,
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data)
          }
        })
        .catch((error) => {
          console.log(error.message())
        })

      toast.promise(promise, {
        loading: 'Saving...',
        success: 'Scene updated!',
        error: 'Error saving scene!',
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addSceneWithDefaults.pending, (state) => {
      state.loading = true
      state.scenes = [] // TODO This is a hacky way of removing the state before filling, need to remove later
    })
    builder.addCase(addSceneWithDefaults.fulfilled, (state, action: PayloadAction<Scene[]>) => {
      state.loading = false
      action.payload.map((item: Scene) => {
        state.scenes.push(item)
      })
    })
    builder.addCase(addSceneWithDefaults.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
  },
})

export const { updateScenes, saveScenes } = sceneSlice.actions
export default sceneSlice.reducer
