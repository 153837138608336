const baseTitle = 'Athletics'
const titleDivider = ' | '

/**
 * @category Routing
 */
export const RoutePaths = {
  baseRoutes: {
    root: {
      url: '/',
      title: baseTitle,
    },
    library: {
      url: '/library',
      title: `${baseTitle + titleDivider + 'Library'}`,
    },
    builder: {
      url: '/builder',
      title: `${baseTitle + titleDivider + 'Builder'}`,
    },
    timeline: {
      url: '/timeline',
      title: `${baseTitle + titleDivider + 'Timeline'}`,
    },
    tvView: {
      url: '/tv-view',
      title: `${baseTitle + titleDivider + 'TV View'}`,
    },
  },
  api: {
    timeline: {
      // picklist: 'https://tv-platform-2022.herokuapp.com/enhanced/timeslines/picklist',
      picklist: 'https://tv-platform-2022.herokuapp.com/timelines/picklist',
      update: 'https://tv-platform-2022.herokuapp.com/storeJsonForTimeline',
      get: 'https://tv-platform-2022.herokuapp.com/getJsonForTimelineWithId/',
    },
  },
}
