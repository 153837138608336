import React, { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { useAppDispatch } from 'hooks/redux.hook'
import { activeScene } from 'store/slices/active-scene.slice'
import TruncateText from 'layouts/dashboard/components/shared/text/truncate'
import SceneFrame from 'types/scene/scene-frame.type'
import { setMainIndex } from 'store/slices/timeline.slice'

interface IProps {
  frame: SceneFrame
  mainIndex: number
}

const SceneSubItem: FC<IProps> = ({ frame, mainIndex }: IProps) => {
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  const setActiveScene = (scene: SceneFrame) => {
    setIsSelected(!isSelected)
    dispatch(activeScene(scene))
    dispatch(setMainIndex(mainIndex))

    document.querySelectorAll('.menu__item--sub').forEach(
      (item) => {
        item.classList.remove('active')
      },
      (item: HTMLDivElement) => {
        item.classList.add('section--active')
      }
    )
  }

  const setFrameIcon = (type: string) => {
    let frameIcon: IconName

    switch (type?.toLowerCase()) {
      case 'image':
        frameIcon = 'image'
        break
      case 'text':
        frameIcon = 'heading'
        break
      case 'video':
        frameIcon = 'video'
        break
      case 'progressBar':
        frameIcon = 'bars-progress'
        break
      case 'countdownCircle':
        frameIcon = 'circle-notch'
        break
      default:
        frameIcon = 'video'
        break
    }

    return frameIcon
  }

  return (
    <div className={`${isSelected ? 'active' : ''} menu__item--sub`} onClick={() => setActiveScene(frame)}>
      <div className="ml-14">
        <FontAwesomeIcon size="lg" className="mr-4" icon={['fas', setFrameIcon(frame.item?.[0].type)]} />
        <TruncateText text={frame.label} maxLength={15} />
        <div className="ml-2">
          {/* {frame.item ? */}
          {/*   frame?.item?.map((item: SceneItemType, index) => { */}
          {/*     return ( */}
          {/*       <div className="ml-4" key={index}> */}
          {/*         <FontAwesomeIcon size="sm" icon={['fas', 'angles-right']} /> */}
          {/*         &nbsp; */}
          {/*         <TruncateText text={frame.label ?? item.type} maxLength={15} /> */}
          {/*       </div> */}
          {/*     ) */}
          {/*   }) */}
          {/*     : <div>something broke</div> */}
          {/* } */}
        </div>
      </div>
    </div>
  )
}

export default SceneSubItem
