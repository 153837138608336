import React, { FC } from 'react'
import { motion } from 'framer-motion'
import Scene from 'types/scene/scene.type'
import SceneItem from './scene-item'

interface IProps {
  item: Scene
  index: number
}

const SceneMenu: FC<IProps> = ({ item, index }: IProps) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <SceneItem scene={item} mainIndex={index} />
    </motion.div>
  )
}

export default SceneMenu
