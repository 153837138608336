import React, { FC } from 'react'
import moment from 'moment'

const Footer: FC = () => {
  const currentYear = moment().year()

  return (
    <footer className="text-center py-2">
      <small>&copy; {currentYear} F45 Training</small>
    </footer>
  )
}

export default Footer
