import React, { FC, useEffect } from 'react'
import TitleTypes from 'types/title.types'
import './index.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'

/**
 * @category Dashboard
 * @param title
 * @constructor
 */
const DashboardIndex: FC<TitleTypes> = ({ title }) => {
  useEffect(() => {
    document.title = title
  }, [])

  return (
    <div className="dashboard">
      <div className="dashboard__content">
        <motion.div animate={{ scale: 1 }} whileHover={{ scale: 1.01 }}>
          <div
            className="dashboard__card rounded-tl-lg rounded-tr-lg sm:rounded-tr-none relative group bg-white p-6
        focus-within:ring-2 focus-within:ring-inset focus-within:ring-f45-blue-500"
          >
            <div>
              <span className="workout-icon create">
                <FontAwesomeIcon size="lg" icon={['fas', 'circle-plus']} />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <a href="#" className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  Create empty workout
                </a>
              </h3>
              <p>
                Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis
                et quo et molestiae.
              </p>
            </div>
            <span className="arrow-out" aria-hidden="true">
              <FontAwesomeIcon size="lg" icon={['fas', 'arrow-up-right-from-square']} />
            </span>
          </div>
        </motion.div>
        <motion.div animate={{ scale: 1 }} whileHover={{ scale: 1.01 }}>
          <div className="dashboard__card relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
            <div>
              <span className="workout-icon configure">
                <FontAwesomeIcon size="lg" icon={['fas', 'gears']} />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <a href="#" className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  Configure new workout
                </a>
              </h3>
              <p>
                Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis
                et quo et molestiae.
              </p>
            </div>
            <span className="arrow-out" aria-hidden="true">
              <FontAwesomeIcon size="lg" icon={['fas', 'arrow-up-right-from-square']} />
            </span>
          </div>
        </motion.div>
        <motion.div animate={{ scale: 1 }} whileHover={{ scale: 1.01 }}>
          <div className="dashboard__card relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
            <div>
              <span className="workout-icon load">
                <FontAwesomeIcon size="lg" icon={['fas', 'users']} />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                <a href="#" className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true"></span>
                  Load a template
                </a>
              </h3>
              <p>
                Doloribus dolores nostrum quia qui natus officia quod et dolorem. Sit repellendus qui ut at blanditiis
                et quo et molestiae.
              </p>
            </div>
            <span className="arrow-out" aria-hidden="true">
              <FontAwesomeIcon size="lg" icon={['fas', 'arrow-up-right-from-square']} />
            </span>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default DashboardIndex
