import React, { FC, useEffect, useState } from 'react'
import './index.scss'
import { Route, Routes, useLocation } from 'react-router-dom'
import Header from './components/shared/header'
import PageLoader from './components/shared/loading/page/page-loader'
import { motion } from 'framer-motion'
import Footer from './components/shared/footer'
import './index.scss'
import { RoutePaths } from 'routes/routes'
import TVViewIndex from 'pages/tv-view'
import LibraryIndex from 'pages/library'
// import BuilderIndex from 'pages/builder/index'
import TimelineIndex from 'pages/timeline'

/**
 * @category Dashboard
 * @category Layouts
 * @constructor
 */
const DashboardLayout: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [isLoading])

  return (
    <div>
      {isLoading && <PageLoader />}
      <Header />

      <motion.div key={location.pathname} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Routes>
          <Route
            path={RoutePaths.baseRoutes.library.url}
            element={<LibraryIndex title={RoutePaths.baseRoutes.library.title} />}
          />
          {/* <Route*/}
          {/*  path={RoutePaths.baseRoutes.builder.url}*/}
          {/*  element={<BuilderIndex title={RoutePaths.baseRoutes.builder.title} />}*/}
          {/* />*/}
          <Route
            path={RoutePaths.baseRoutes.timeline.url}
            element={<TimelineIndex title={RoutePaths.baseRoutes.timeline.title} />}
          />
          <Route
            path={RoutePaths.baseRoutes.tvView.url}
            element={<TVViewIndex title={RoutePaths.baseRoutes.tvView.title} />}
          />
        </Routes>
      </motion.div>

      <Footer />
    </div>
  )
}

export default DashboardLayout
