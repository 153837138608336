import React, { FC } from 'react'

interface IText {
  text: string | undefined | any
  maxLength: number
}

const TruncateText: FC<IText> = ({ text, maxLength }) => {
  return (
    <>
      {text.substring(0, maxLength)}
      {text?.length > maxLength && '...'}
    </>
  )
}

export default TruncateText
