import { createSlice } from '@reduxjs/toolkit'
import TimelineType from 'types/timeline.types'

interface TimelineState {
  loading: boolean
  error?: string | null
  timeline: TimelineType
}

const initialState: TimelineState = {
  loading: true,
  error: null,
  timeline: {
    id: null,
    runtime: 0,
    mainIndex: null,
  },
}

/**
 *
 */
export const timelineSlice = createSlice({
  name: 'timeline/set',
  initialState,
  reducers: {
    setRuntime: (state, { payload }) => {
      state.timeline.runtime = payload
    },
    setTimeline: (state, { payload }) => {
      state.timeline = payload
    },
    setMainIndex: (state, { payload }) => {
      state.timeline.mainIndex = payload
    },
  },
})

export const { setTimeline, setRuntime, setMainIndex } = timelineSlice.actions
export default timelineSlice.reducer
