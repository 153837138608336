import React, { FC } from 'react'
import './page-loader.scss'
import LoadImage from 'assets/images/preloaders/blue/preloader_bl70.svg'

/**
 * @category Loading
 * @category Components
 * @constructor
 */
const PageLoader: FC = () => {
  return (
    <div className="loading__panel">
      <div className="loading__content">
        <img src={LoadImage} alt="Loading" />
      </div>
    </div>
  )
}

export default PageLoader
