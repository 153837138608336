import React, { FC, useEffect, useState } from 'react'
import './index.scss'
import { Outlet } from 'react-router-dom'
import Header from './components/shared/header'
import PageLoader from './components/shared/loading/page/page-loader'

/**
 * @category Dashboard
 * @category Layouts
 * @constructor
 */
const DashboardFullLayout: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [isLoading])

  return (
    <div>
      {isLoading && <PageLoader />}
      <Header />

      <div className="flex flex-row justify-center animate__animated animate__fadeIn mt-4">
        <div className="basis-8/12">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default DashboardFullLayout
