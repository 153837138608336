import React, { FC, useEffect } from 'react'
import TitleType from 'types/title.types'
// import Timeline from './panels/timeline'
import SceneIndex from './panels/scenes'

import './index.scss'
import DetailsIndex from './panels/details'
import PreviewIndex from './panels/preview'

const TimelineIndex: FC<TitleType> = ({ title }) => {
  useEffect(() => {
    document.title = title
  }, [])

  return (
    <div className="dashboard__container">
      <div className="column__left">
        <SceneIndex />
      </div>
      <div className="column__center">
        <PreviewIndex />
      </div>
      <div className="column__right">
        <DetailsIndex />
      </div>
      {/* <Timeline />*/}
    </div>
  )
}

export default TimelineIndex
