import React from 'react'

import './index.scss'
import { useAppSelector } from 'hooks/redux.hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PreviewIndex = () => {
  const { timeline } = useAppSelector((state) => state.timeline)

  return (
    <div className="preview__container">
      <div className="preview__header">
        <div className="text-left">Preview</div>
        <div className="text-right">
          <FontAwesomeIcon className="mr-1" icon={['far', 'clock']} /> {timeline.runtime}
        </div>
      </div>

      <div className="preview__content">
        <div>Preview content</div>
      </div>
    </div>
  )
}

export default PreviewIndex
