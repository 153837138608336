import React, { Fragment, FC, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import F45LogoBlue from 'assets/images/logos/f45b-logo.svg'
import F45LogoWhite from 'assets/images/logos/f45w-logo.svg'
import { NavLink } from 'react-router-dom'
import { RoutePaths } from 'routes/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classNames } from 'assets/utils/classNames'
import AppModeButton from '../app-mode-button'

import './header.scss'

/**
 * @category Components
 * @constructor
 */
const Header: FC = () => {
  const [darkMode, setDarkMode] = useState(false)

  const toggleDarkMode = () => {
    setDarkMode(!darkMode)
  }

  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <a href={RoutePaths.baseRoutes.root.url} className="logo">
                    <img className="block h-8 w-auto" src={darkMode ? F45LogoWhite : F45LogoBlue} alt="F45 Athletics" />
                  </a>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  <NavLink
                    className={(navData) =>
                      (navData.isActive ? 'border-f45-blue-500 dark:border-f45-blue-500' : '') + ' nav-link'
                    }
                    to={RoutePaths.baseRoutes.library.url}
                  >
                    Library
                  </NavLink>
                  {/* <NavLink*/}
                  {/*  className={(navData) =>*/}
                  {/*    (navData.isActive ? 'border-f45-blue-500 dark:border-f45-blue-500' : '') + ' nav-link'*/}
                  {/*  }*/}
                  {/*  to={RoutePaths.baseRoutes.builder.url}*/}
                  {/* >*/}
                  {/*  Builder*/}
                  {/* </NavLink>*/}
                  <NavLink
                    className={(navData) =>
                      (navData.isActive ? 'border-f45-blue-500 dark:border-f45-blue-500' : '') + ' nav-link'
                    }
                    to={RoutePaths.baseRoutes.timeline.url}
                  >
                    Timeline
                  </NavLink>
                  <NavLink
                    className={(navData) =>
                      (navData.isActive ? 'border-f45-blue-500 dark:border-f45-blue-500' : '') + ' nav-link'
                    }
                    to={RoutePaths.baseRoutes.tvView.url}
                  >
                    TV View
                  </NavLink>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <a type="button" className="notifications">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </a>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative" style={{ position: 'initial' }}>
                  <Menu.Button className="menu btn">
                    <div className="user-menu">
                      <div>
                        <FontAwesomeIcon size="lg" icon={['fas', 'user']} />
                      </div>
                      <div>
                        <FontAwesomeIcon size="lg" icon={['fas', 'chevron-down']} />
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      id="user-menu"
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none menu-top"
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/profile"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            <div>
                              <span className="mr-2">
                                <FontAwesomeIcon size="sm" icon={['fas', 'id-card']} />
                              </span>
                              Profile
                            </div>
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/profile/settings"
                            className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                          >
                            <span className="mr-2">
                              <FontAwesomeIcon size="sm" icon={['fas', 'gears']} />
                            </span>
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {() => (
                          <div className="block px-4 py-2 text-sm text-gray-700">
                            <div>
                              <small>Signed in as: sample@email.com</small>
                            </div>
                            <span>
                              <b></b>
                            </span>
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/sign-out"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700 border-t-1 border-f45-grey-100'
                            )}
                          >
                            <span className="mr-2">
                              <FontAwesomeIcon size="sm" icon={['fas', 'sign-out']} />
                            </span>
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <AppModeButton toggleDarkMode={toggleDarkMode} />
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="pt-2 pb-4 space-y-1">
              <Disclosure.Button
                as="a"
                href={RoutePaths.baseRoutes.library.url}
                className="bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                Library
              </Disclosure.Button>
              {/* <Disclosure.Button*/}
              {/*  as="a"*/}
              {/*  href={RoutePaths.baseRoutes.builder.url}*/}
              {/*  className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"*/}
              {/* >*/}
              {/*  Builder*/}
              {/* </Disclosure.Button>*/}
              <Disclosure.Button
                as="a"
                href={RoutePaths.baseRoutes.tvView.url}
                className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
              >
                TV View
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Header
