import React, { FC } from 'react'
import './index.scss'
import { Outlet } from 'react-router-dom'

/**
 * @category Login
 * @constructor
 */
const LoginLayout: FC = () => {
  return (
    <div id="login-panel">
      <Outlet />
    </div>
  )
}

export default LoginLayout
