import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch, useAppSelector } from 'hooks/redux.hook'
import { saveScenes, updateScenes } from 'store/api/scenes.slice'
import { updateActiveScene } from 'store/slices/active-scene.slice'
import { motion } from 'framer-motion'
import { RootState } from 'store'

import './index.scss'
// import ColorPicker from './components/color-picker'

/**
 *
 * @constructor
 */
const DetailsIndex = () => {
  // const [currentColor, setCurrentColor] = useState({
  //   hex: '',
  // })
  const { timeline } = useAppSelector((state: RootState) => state.timeline)
  const { activeScene } = useAppSelector((state: any) => state.activeScene)
  const [currentActiveScene, setCurrentActiveScene] = useState({
    h: 0,
    w: 0,
    x: 0,
    y: 0,
    z: 0,
    d: 0,
    item: [
      {
        d: '',
        fileName: '',
        settings: {
          align: '',
          d: '',
          vAlign: '',
          rotationOpt: '',
          size: '',
          titleText: '',
          bg: '',
          bga: '',
        },
        txt: [
          {
            val: '',
            d: '',
          },
        ],
      },
    ],
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    setCurrentActiveScene(activeScene)
  }, [activeScene])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const { name, value } = event.target

    /**
     * Map items to scene state
     */
    // TODO Only active when using text changes
    if (name.includes('item') || name.includes('settings') || name.includes('txt')) {
      const nameArray: string[] = name.split('-')
      const itemIndex: any = nameArray[1]
      const itemKey: string = nameArray[2]
      const subIndex: any = nameArray[3]
      console.log(nameArray)

      if (nameArray[0] === 'item') {
        const items = [...activeScene.item]

        items[itemIndex] = {
          ...items[itemIndex],
          [itemKey]: value,
        }

        const newActiveSceneState = {
          ...currentActiveScene,
          item: items,
        }
        setCurrentActiveScene(newActiveSceneState)
      }

      if (nameArray[0] === 'settings') {
        const settings = [...activeScene.item]

        settings[itemIndex] = {
          ...settings[itemIndex],
          settings: {
            ...settings[itemIndex].settings,
            [itemKey]: value,
          },
        }

        const newActiveSceneState: any = {
          ...currentActiveScene,
          item: settings,
        }
        setCurrentActiveScene(newActiveSceneState)
      }

      // TODO Placeholder stuff for text setting changes
      if (nameArray[0] === 'txt') {
        const text = [...activeScene.item]

        text[itemIndex] = {
          ...text[itemIndex],
          txt: [
            ...text[itemIndex].txt.slice(0, subIndex),
            { ...text[itemIndex].txt[subIndex], [itemKey]: value },
            ...text[itemIndex].txt.slice(subIndex + 1),
          ],
        }

        const newActiveSceneState: any = {
          ...currentActiveScene,
          item: text,
        }

        console.log(newActiveSceneState)
        setCurrentActiveScene(newActiveSceneState)
      }
    } else {
      setCurrentActiveScene({
        ...activeScene,
        [name]: value,
      })
    }
  }
  const handleStateUpdate = () => {
    dispatch(updateActiveScene(currentActiveScene))
    dispatch(
      updateScenes({
        scene: currentActiveScene,
        mainIndex: timeline.mainIndex,
      })
    )
    dispatch(saveScenes({ id: timeline.id }))
  }

  return (
    <div className="details__container">
      <div className="details__header">
        <div>Details</div>
        <div>
          <FontAwesomeIcon icon={['fas', 'trash-alt']} />
        </div>
      </div>

      <div className="details__content">
        {activeScene.label && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="pb-4">
              <b>
                <u>
                  {activeScene.label} - {activeScene.type}
                </u>
              </b>
            </div>

            <div className="details__content--settings">
              <div className="section">
                <h2 className="title">Dimensions:</h2>
                <div className="grid grid-cols-2 gap-2 py-2">
                  <div className="flex flex-row items-center">
                    <div className="mr-2">H:</div>
                    <div>
                      <input
                        onChange={(event) => handleInputChange(event)}
                        onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                        name="h"
                        value={currentActiveScene.h ?? ''}
                        type="text"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="flex flex-row items-center">
                      <div className="mr-2">W:</div>
                      <div>
                        <input
                          onChange={(event) => handleInputChange(event)}
                          onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                          name="w"
                          value={currentActiveScene.w ?? ''}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 py-2">
                  <div className="flex flex-row items-center">
                    <div className="mr-2">X:</div>
                    <div>
                      <input
                        onChange={(event) => handleInputChange(event)}
                        onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                        name="x"
                        value={currentActiveScene.x ?? ''}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="flex flex-row items-center">
                    <div className="mr-2">Y:</div>
                    <div>
                      <input
                        onChange={(event) => handleInputChange(event)}
                        onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                        name="y"
                        value={currentActiveScene.y ?? ''}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-2 py-2">
                  <div className="flex flex-row items-center">
                    <div className="mr-2">Z:</div>
                    <div>
                      <input
                        onChange={(event) => handleInputChange(event)}
                        onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                        name="z"
                        value={currentActiveScene.z ?? ''}
                        type="text"
                      />
                    </div>
                  </div>
                </div>

                {currentActiveScene?.item?.map((frameItem: any, index: number) => (
                  <div key={index} className="section">
                    <div className="content">
                      {currentActiveScene.item[index].d && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">Duration:</div>
                          <div className="basis-2/3">
                            <input
                              className="w-12"
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'item-' + index + '-d'}
                              value={currentActiveScene.item[index].d ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <h2 className="title">Item Settings:</h2>

                    <div className="content">
                      {currentActiveScene.item[index].settings.d && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">Countdown:</div>
                          <div className="basis-2/3">
                            <input
                              className="w-12"
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'settings-' + index + '-d'}
                              value={currentActiveScene.item[index].settings.d ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}

                      {currentActiveScene.item[index].fileName && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">File:</div>
                          <div className="basis-2/3">
                            <input
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'item-' + index + '-fileName'}
                              value={currentActiveScene.item[index].fileName ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}
                      {currentActiveScene.item[index].settings.align && (
                        <>
                          <div className="flex flex-row items-center py-2">
                            <div className="basis-1/3">Align:</div>
                            <div className="basis-2/3">
                              <input
                                onChange={(event) => handleInputChange(event)}
                                onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                                name={'settings-' + index + '-align'}
                                value={currentActiveScene.item[index].settings.align ?? ''}
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="flex flex-row items-center py-2">
                            <div className="basis-1/3">Vertical Align:</div>
                            <div className="basis-2/3">
                              <input
                                onChange={(event) => handleInputChange(event)}
                                onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                                name={'settings-' + index + '-vAlign'}
                                value={currentActiveScene.item[index].settings.vAlign ?? ''}
                                type="text"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {currentActiveScene.item[index].settings.vAlign && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">Vertical Align:</div>
                          <div className="basis-2/3">
                            <input
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'settings-' + index + '-vAlign'}
                              value={currentActiveScene.item[index].settings.vAlign ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}

                      {currentActiveScene.item[index].settings.rotationOpt && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">rotationOpt:</div>
                          <div className="basis-2/3">
                            <input
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'settings-' + index + '-rotationOpt'}
                              value={currentActiveScene.item[index].settings.rotationOpt ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}

                      {currentActiveScene.item[index].settings.size && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">Size:</div>
                          <div className="basis-2/3">
                            <input
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'settings-' + index + '-size'}
                              value={currentActiveScene.item[index].settings.size ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}

                      {currentActiveScene.item[index].settings.bg && (
                        <div className="flex flex-row items-center py-2">
                          {/* TODO For testing START, remove after demo */}
                          <div className="basis-1/3">BG:</div>
                          <div className="basis-2/3">
                            <input
                              onChange={(event) => handleInputChange(event)}
                              id="color-selector"
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'settings-' + index + '-bg'}
                              value={currentActiveScene.item[index].settings.bg}
                              type="text"
                              // ref={colorRef}
                              onFocus={(event) => handleInputChange(event)}
                            />
                            {/* TODO For testing END, remove after demo */}

                            {/* <ColorPicker */}
                            {/*   color={currentActiveScene.item[index].settings.bg} */}
                            {/*   currentActiveScene={currentActiveScene} */}
                            {/*   index={index} */}
                            {/*   handleInputChange={(event) => handleInputChange(event)} */}
                            {/*   currentColor={currentColor} */}
                            {/*   setCurrentColor={setCurrentColor} */}
                            {/* /> */}
                          </div>
                        </div>
                      )}

                      {currentActiveScene.item?.[index].settings?.bga && (
                        <div className="flex flex-row items-center py-2">
                          <div className="basis-1/3">BGA:</div>
                          <div className="basis-2/3">
                            <input
                              onChange={(event) => handleInputChange(event)}
                              onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                              name={'settings-' + index + '-bga'}
                              value={currentActiveScene.item[index].settings.bga ?? ''}
                              type="text"
                            />
                          </div>
                        </div>
                      )}

                      {currentActiveScene.item?.[index].txt?.map((text: any, i: number) => (
                        <div key={i} className="section">
                          <h2 className="title">Text Settings:</h2>
                          <div className="flex flex-row items-center py-2">
                            <div className="basis-1/3">Duration:</div>
                            <div className="basis-2/3">
                              <input
                                onChange={(event) => handleInputChange(event)}
                                onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                                name={'txt-' + index + '-d' + '-' + i}
                                value={currentActiveScene.item[index].txt[i].d ?? ''}
                                type="text"
                              />
                            </div>
                          </div>

                          <div className="flex flex-row items-center py-2">
                            <div className="basis-1/3">Value:</div>
                            <div className="basis-2/3">
                              <input
                                onChange={(event) => handleInputChange(event)}
                                onBlur={() => dispatch(updateActiveScene(currentActiveScene))}
                                name={'txt-' + index + '-val' + '-' + i}
                                value={currentActiveScene.item[index].txt[i].val ?? ''}
                                type="text"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <div className="py-4">
                <button onClick={() => handleStateUpdate()} className="btn btn-default">
                  Save
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  )
}

export default DetailsIndex
