import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AddScene from '../../modals/add-scene'
import { useAppDispatch, useAppSelector } from 'hooks/redux.hook'
import { useTimeFormatter } from 'hooks/time-format.hook'
import SceneMenu from './components/scene-menu'
import Scene from 'types/scene/scene.type'
import SingleLoader from 'layouts/dashboard/components/shared/loading/single'
import { setRuntime } from 'store/slices/timeline.slice'
import TruncateText from 'layouts/dashboard/components/shared/text/truncate'

import './index.scss'

const SceneIndex: FC = () => {
  const { scenes, loading } = useAppSelector((state) => state.scenes)
  const [projectName, setProjectName] = useState('Untitled')
  const [editing, setEditing] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    let sceneTime = 0
    scenes.forEach((scene: Scene) => {
      sceneTime = sceneTime + parseInt(String(scene.d))
    })
    dispatch(setRuntime(useTimeFormatter(sceneTime)))
  }, [scenes])

  useEffect(() => {
    const projectNameInput = document.getElementById('project-name')
    projectNameInput?.addEventListener('blur', () => {
      setEditing(!editing)
    })
  })

  const handleProjectName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value)
  }

  const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setEditing(!editing)
    }
  }

  const renderMenuList = () => {
    if (scenes.length > 0) {
      return (
        <>
          {scenes.length > 0 &&
            scenes.map((item: Scene, index: number) => {
              return <SceneMenu key={index} item={item} index={index} />
            })}
        </>
      )
    } else {
      return <div className="text-center">Please add a scene</div>
    }
  }

  return (
    <div className="scene__container">
      <div className="scene__header flex flex-row justify-between items-center">
        <div className="flex flex-row align-baseline items-center">
          <FontAwesomeIcon size="lg" className="mr-2" icon={['fas', 'tv']} />
          {editing ? (
            <div className="flex flex-row items-center">
              <input
                onKeyDown={(event) => handleEnterPress(event)}
                id="project-name"
                className="mr-2"
                type="text"
                onChange={handleProjectName}
                value={projectName}
                style={{
                  height: 30,
                  width: 150,
                }}
              />
              <FontAwesomeIcon className="mr-1" onClick={() => setEditing(!editing)} icon={['fas', 'check']} />
              <FontAwesomeIcon onClick={() => setEditing(!editing)} icon={['fas', 'times']} />
            </div>
          ) : (
            <div>
              <span className="mr-2" onClick={() => setEditing(!editing)}>
                <TruncateText text={projectName} maxLength={10} />
              </span>
              <FontAwesomeIcon size="sm" onClick={() => setEditing(!editing)} icon={['fas', 'pencil']} />
            </div>
          )}
        </div>
        <div>
          <AddScene editing={editing} />
        </div>
      </div>

      <div className="scene__content">
        {loading && (
          <div className="py-14">
            <SingleLoader />
          </div>
        )}
        {renderMenuList()}
      </div>
    </div>
  )
}

export default SceneIndex
