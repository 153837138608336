import React, { FC } from 'react'
import LoadImage from 'assets/images/preloaders/blue/preloader_bl48.svg'

interface IProps {
  size?: string | undefined
  color?: string | undefined
  name?: string | undefined
}

const SingleLoader: FC<IProps> = () => {
  // TODO working with this later to allow color/size switching
  // const determineSize = () => {
  //     let iconName =  'preloader'
  //     let iconColor = '_bl'
  //     let iconSize = '24'
  //
  //     switch (size):
  //         case:
  //             'lg'
  //         break
  //         default:
  //             break
  // }

  return (
    <span style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={LoadImage} alt="Loading" />
    </span>
  )
}

export default SingleLoader
