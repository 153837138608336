import React, { FC } from 'react'
import { Routes, Route } from 'react-router-dom'
import LoginIndex from 'pages/login'
import DashboardLayout from 'layouts/dashboard'
import DashboardIndex from 'pages/dashboard'
import LoginLayout from 'layouts/login'
import TVViewIndex from 'pages/tv-view'
import LibraryIndex from 'pages/library'
// import BuilderIndex from 'pages/builder'
import { RoutePaths } from './routes'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import DashboardFullLayout from 'layouts/dashboard/dash-full'
import { AnimatePresence, motion } from 'framer-motion'
import WorkoutIndex from '../pages/timeline'

/**
 * Builds library for Font Awesome Icons usage https://fontawesome.com/
 */
library.add(fas, fab, far)

/**
 * @category Routing
 * @constructor
 */
const Router: FC = () => {
  return (
    <AnimatePresence>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Routes>
          <Route element={<LoginLayout />}>
            <Route path="/login" element={<LoginIndex />} />
          </Route>
          <Route element={<DashboardFullLayout />}>
            <Route path="/" element={<DashboardIndex title={RoutePaths.baseRoutes.root.title} />} />
          </Route>

          <Route element={<DashboardLayout />}>
            <Route
              path={RoutePaths.baseRoutes.library.url}
              element={<LibraryIndex title={RoutePaths.baseRoutes.library.title} />}
            />
            {/* <Route*/}
            {/*  path={RoutePaths.baseRoutes.builder.url}*/}
            {/*  element={<BuilderIndex title={RoutePaths.baseRoutes.builder.title} />}*/}
            {/* />*/}
            <Route
              path={RoutePaths.baseRoutes.timeline.url}
              element={<WorkoutIndex title={RoutePaths.baseRoutes.timeline.title} />}
            />
            <Route
              path={RoutePaths.baseRoutes.tvView.url}
              element={<TVViewIndex title={RoutePaths.baseRoutes.tvView.title} />}
            />
          </Route>
        </Routes>
      </motion.div>
    </AnimatePresence>
  )
}

export default Router
