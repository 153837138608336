import { configureStore, Middleware } from '@reduxjs/toolkit'
import sceneReducer from './api/scenes.slice'
import logger from 'redux-logger'
import activeSceneSlice from './slices/active-scene.slice'
import timelineSlice from './slices/timeline.slice'

/*
  Reducers
 */
const allReducers = {
  scenes: sceneReducer,
  activeScene: activeSceneSlice,
  timeline: timelineSlice,
}

const middleware: Middleware[] = []
let devToolsActivated = false

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
  devToolsActivated = true
}

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: devToolsActivated, // remove when live
  reducer: allReducers,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
