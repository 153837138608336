import React, { FC, useEffect } from 'react'
import TitleType from 'types/title.types'
// import React, { useState } from 'react'

const LibraryIndex: FC<TitleType> = ({ title }) => {
  // const [currentActivity, setCurrentActivity] = useState('')
  useEffect(() => {
    document.title = title
  }, [])
  // const addNewPod = (activity: string) => {
  //   setCurrentActivity(activity)
  // }

  return (
    <div className="dashboard__container">
      <div className="column__left">{/* <BuilderLeftPanel addNewPod={addNewPod} />*/}</div>
      <div className="column__center">Library page container</div>
      <div className="column__right">{/* <BuilderRightPanel currentActivity={currentActivity} />*/}</div>
    </div>
  )
}

export default LibraryIndex
