import React, { useState } from 'react'
import { classNames } from 'assets/utils/classNames'
import { Switch } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDarkMode } from 'hooks/theme.hook'

interface IProps {
  toggleDarkMode: () => void
}

/**
 *
 * @constructor
 */
const AppModeButton = ({ toggleDarkMode }: IProps) => {
  const [enabled, setEnabled] = useState(false)
  const [isDark, setIsDark] = useDarkMode()

  const switchTheme = () => {
    setIsDark(!isDark)
    toggleDarkMode()
  }

  return (
    <div className="flex items-center ml-2">
      <Switch
        checked={enabled}
        onChange={setEnabled}
        // onClick={() => setIsDark(!isDark)}
        onClick={() => switchTheme()}
        className={classNames(
          enabled ? 'bg-f45-blue-600 toggle' : 'toggle bg-gray-200',
          'dark:bg-dark-mode-blue-medium relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-300 focus:outline-none'
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200 dark:bg-dark-mode-blue-medium-light dark:text-yellow-300'
          )}
        >
          <span
            className={classNames(
              enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            {' '}
            <FontAwesomeIcon size="sm" icon={['fas', 'sun']} />
          </span>
          <span
            className={classNames(
              enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
          >
            <FontAwesomeIcon size="sm" icon={['fas', 'moon']} />
          </span>
        </span>
      </Switch>
    </div>
  )
}

export default AppModeButton
