import { createSlice } from '@reduxjs/toolkit'
// import Scene from 'types/scene/scene.type'
import SceneFrame from '../../types/scene/scene-frame.type'

interface ActiveSceneState {
  loading: boolean
  error?: string | null
  activeScene: Array<SceneFrame>
}

const initialState: ActiveSceneState = {
  loading: true,
  error: null,
  activeScene: [],
}

/**
 *
 */
export const activeSceneSlice = createSlice({
  name: 'scenes/active',
  initialState,
  reducers: {
    activeScene: (state, { payload }) => {
      state.activeScene = payload
    },
    updateActiveScene: (state, { payload }) => {
      state.activeScene = payload
    },
  },
})

export const { activeScene, updateActiveScene } = activeSceneSlice.actions
export default activeSceneSlice.reducer
