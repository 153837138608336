import React, { FC, Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppDispatch } from 'hooks/redux.hook'
import toast, { Toaster } from 'react-hot-toast'
import { addSceneWithDefaults } from 'store/api/scenes.slice'
import { setTimeline } from 'store/slices/timeline.slice'
import { RoutePaths } from 'routes/routes'
import axios from 'axios'

import './add-scene.scss'

interface IProps {
  editing: boolean
}

type Scene = {
  id: number | string
  name: string
}

/**
 *
 * @constructor
 */
const AddScene: FC<IProps> = ({ editing }) => {
  const [addSceneOpen, setAddSceneOpen] = useState(false)
  const [timelineID, setTimelineID] = useState<number | null>(null)
  const [timelines, setTimelines] = useState([])
  const [selectedScenes, setSelectedScenes] = useState(Array<Scene>)
  const dispatch = useAppDispatch()

  useEffect(() => {
    axios.get(RoutePaths.api.timeline.picklist).then((response) => setTimelines(response.data.data))
  }, [])

  /**
   *
   * @param event
   * @param id
   */
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    let sceneList = [...selectedScenes]

    setTimelineID(id)

    if (event.target.checked) {
      sceneList = [
        ...selectedScenes,
        {
          id: id,
          name: event.target.value,
        },
      ]
    } else {
      sceneList = sceneList.filter((object) => {
        return object.name != event.target.value
      })
    }

    setSelectedScenes(sceneList)
  }
  /**
   * Dispatch to redux
   */
  const handleAddedScenes = () => {
    if (selectedScenes.length > 0) {
      selectedScenes.forEach(() => {
        if (timelineID != null) {
          dispatch(addSceneWithDefaults(timelineID))
          dispatch(setTimeline({ id: timelineID }))
        }
      })

      const message = selectedScenes.length > 1 ? 'Scenes' : 'Scene'
      toast.success(`${message}  added!`)

      setSelectedScenes([])
      setAddSceneOpen(!addSceneOpen)
    } else {
      toast.error('Please add a scene or timeline!')
    }
  }

  // const scenes = [
  //   // { id: 1, name: 'Workout' },
  //   // { id: 2, name: 'Warm-up' },
  //   // { id: 3, name: 'Warm-up, Get ready' },
  //   // { id: 4, name: 'Demo' },
  //   { id: 5, name: 'Exercise Intro' },
  //   // { id: 6, name: 'Cooldown' },
  // ]

  return (
    <>
      {!editing && (
        <div className="add-scene-btn">
          <FontAwesomeIcon className="mr-2" icon={['fas', 'trash-alt']} />
          <FontAwesomeIcon onClick={() => setAddSceneOpen(!addSceneOpen)} icon={['fas', 'file-import']} />
        </div>
      )}

      <Transition.Root show={addSceneOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setAddSceneOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="add-scene-modal">
            <div className="modal-content">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="dialog-panel">
                  <div className="text-xl pb-4 text-center">
                    <div>Open a timeline</div>

                    <div className="py-4">
                      <input type="text" placeholder="Search timelines" />
                    </div>

                    {/* <div className="flex flex-row items-center"> */}
                    {/*   <div className="py-4"> */}
                    {/*     <input name="timelines" type="checkbox" /> Triple Double */}
                    {/*   </div> */}
                    {/* </div> */}
                  </div>
                  {/* <div className="text-xl pb-4 text-center border-b border-gray-200 dark:border-dark-mode-blue-medium"> */}
                  {/*   Add a scene */}
                  {/* </div> */}
                  <ul role="list" className="divide-y divide-gray-200 dark:divide-dark-mode-blue-medium items-center">
                    {timelines?.map((item: any) => (
                      <li key={item.value} className="py-4">
                        <input
                          name="scenes"
                          className="mr-2"
                          type="radio"
                          value={item.text}
                          onChange={(event) => handleCheckboxChange(event, item.value)}
                        />
                        {item.text}
                      </li>
                    ))}

                    {/* {scenes.map((item) => ( */}
                    {/*   <li key={item.id} className="py-4"> */}
                    {/*     <input */}
                    {/*       name="scenes" */}
                    {/*       type="checkbox" */}
                    {/*       value={item.name} */}
                    {/*       onChange={(event) => handleCheckboxChange(event, item.id)} */}
                    {/*     /> */}
                    {/*     /!* &nbsp;{item.name} *!/ */}
                    {/*     &nbsp;Triple Double */}
                    {/*   </li> */}
                    {/* ))} */}
                  </ul>

                  <button className="btn default" onClick={handleAddedScenes}>
                    Add
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Toaster position="bottom-right" />
      {/*   {(t) => ( */}
      {/*     <div */}
      {/*       aria-live="assertive" */}
      {/*       className={`pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 ${ */}
      {/*         t.visible ? 'animate-enter' : 'animate-leave' */}
      {/*       }`} */}
      {/*     > */}
      {/*       <div className="flex w-full flex-col items-center space-y-4 sm:items-end"> */}
      {/*         <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"> */}
      {/*           <div className="p-4"> */}
      {/*             <div className="flex items-start"> */}
      {/*               <div className="flex-shrink-0"> */}
      {/*                 <svg */}
      {/*                   className="h-6 w-6 text-green-400" */}
      {/*                   xmlns="http://www.w3.org/2000/svg" */}
      {/*                   fill="none" */}
      {/*                   viewBox="0 0 24 24" */}
      {/*                   strokeWidth="1.5" */}
      {/*                   stroke="currentColor" */}
      {/*                   aria-hidden="true" */}
      {/*                 > */}
      {/*                   <path */}
      {/*                     strokeLinecap="round" */}
      {/*                     strokeLinejoin="round" */}
      {/*                     d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" */}
      {/*                   /> */}
      {/*                 </svg> */}
      {/*               </div> */}
      {/*               <div className="ml-3 w-0 flex-1 pt-0.5"> */}
      {/*                 <p className="text-sm font-medium text-gray-900">Scene(s) saved!</p> */}
      {/*                 <p className="mt-1 text-sm text-gray-500">notes....maybe?</p> */}
      {/*               </div> */}
      {/*               <div className="ml-4 flex flex-shrink-0"> */}
      {/*                 <button onClick={() => toast.dismiss(t.id)}>X</button> */}
      {/*               </div> */}
      {/*             </div> */}
      {/*           </div> */}
      {/*         </div> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   )} */}
      {/* </Toaster> */}
    </>
  )
}

export default AddScene
