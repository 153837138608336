import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TruncateText from 'layouts/dashboard/components/shared/text/truncate'
import { useTimeFormatter } from 'hooks/time-format.hook'
import SceneFrame from 'types/scene/scene-frame.type'
import SceneSubItem from './scene-sub-item'
import Scene from 'types/scene/scene.type'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import { motion } from 'framer-motion'

interface IProps {
  scene: Scene
  mainIndex: number
}

const SceneIteration = ({ scene, mainIndex }: IProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  const toggleIcon = () => {
    let icon: IconName = 'caret-down'

    if (!isActive) {
      icon = 'caret-right'
    }

    return icon
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <div className="menu__item" onClick={() => setIsActive(!isActive)}>
        <div>
          <FontAwesomeIcon size="lg" className="mr-2" icon={['fas', toggleIcon()]} />
          <FontAwesomeIcon size="lg" className="mr-2" icon={['fas', 'video']} />
          <TruncateText text={scene.label} maxLength={10} />
        </div>
        <div>{useTimeFormatter(scene.d)}</div>
      </div>

      {isActive && (
        <div className="animate__animated animate__fadeIn">
          {scene.fr
            ? scene.fr.map((frame: SceneFrame, index: number) => {
                return <SceneSubItem key={index} frame={frame} mainIndex={mainIndex} />
              })
            : 'Nothing to see here folks'}
        </div>
      )}
    </motion.div>
  )
}

export default SceneIteration
